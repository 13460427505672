import React from 'react';
import PreloadingComponent from '@izi-logistics/common/lib/components/PreloadingComponent';
import { BaseUserAppTYpe } from '@izi-logistics/common/lib/stores/common';

const RegisterPage = React.lazy(() => import('@izi-logistics/common/lib/pages/RegisterPage/RegisterPage'));

const Register = () => (
  <PreloadingComponent>
    <RegisterPage isAffiliate appType={BaseUserAppTYpe.AFFILIATE} />
  </PreloadingComponent>
);
export default Register;
