import React from 'react';
import { inject } from 'mobx-react';
import { BasicSideBar } from '@izi-logistics/common/lib/components';
import { BasicSideBarMenuItem } from '@izi-logistics/common/lib/components';
import { DashboardIcon } from '@izi-logistics/common/lib/components/icons';
import { MainStoreProps } from '@izi-logistics/common/lib/models';
// import GroupOutlinedIcon from '@material-ui/icons/GroupOutlined';
import { useTranslation } from 'react-i18next';
import { KEYS } from '@izi-logistics/common/lib/i18n';
import { client } from '../../api/apolloClient';

const SidebarMenu = inject('store')(({ store }: MainStoreProps) => {
  const langMenu = useTranslation(KEYS.MENU);
  const menu: BasicSideBarMenuItem[] = [
    {
      icon: (
        <span>
          <DashboardIcon title={langMenu.t('menu_sidebar_dashboard')} />
        </span>
      ),
      title: langMenu.t('menu_sidebar_dashboard'),
      path: '/dashboard',
      _id: 'menu-sidebar-dashboard',
    },
    // {
    //   icon: <GroupOutlinedIcon />,
    //   title: langMenu.t('menu_sidebar_team_title'),
    //   path: '/team',
    //   _id: 'menu-sidebar-team',
    // },
  ];

  return <BasicSideBar menu={menu} config={{ client }} />;
});
export default SidebarMenu;
