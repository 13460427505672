import React from 'react';
import { CompletePaperLayout } from '@izi-logistics/common/lib/components/CompletePaperLayout';
import { Redirect, Route, Switch } from 'react-router';
import SetPassword from './pages/Auth/SetPassword/SetPassword';
import SidebarMenu from './components/common/SidebarMenu';
import { client } from './api/apolloClient';
import Account from './pages/Account/Account';
import Team from './pages/TeamPage';
import ChatContentComponent from './pages/Chat';
import PreloadingComponent from '@izi-logistics/common/lib/components/PreloadingComponent';

const DashboardList = React.lazy(() => import('./pages/Dashboard'));

export default function RootRoutes() {
  const routes = (
    <Switch>
      <Route exact path="/change-password" component={SetPassword} />
      <Route exact path="/account/details" component={Account} />
      <Route exact path="/account/settings" component={Account} />
      <Route path="/account">
        <Redirect to={'/account/details'} />
      </Route>
      <Route exact path="/team" component={Team} />
      <Route path="/chats" component={ChatContentComponent} />
      <Route
        path="/dashboard"
        component={(p: any) => (
          <PreloadingComponent>
            <DashboardList {...p} config={{ client }} />
          </PreloadingComponent>
        )}
      />
      <Route path="*">
        <Redirect to="/dashboard" />
      </Route>
    </Switch>
  );
  return <CompletePaperLayout config={{ client }} sideBarMenuContent={<SidebarMenu />} pageContent={routes} />;
}
