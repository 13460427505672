import { AccountStore } from './AccountStore';
import { RegisterStore } from './RegisterStore';
import { PreLoginStore } from './PreLoginStore';
import { types, castToSnapshot, applySnapshot, tryResolve } from 'mobx-state-tree';
import { MaybeEmptyModel } from '@izi-logistics/common/lib/stores';
import { WarehouseStore } from './WarehouseStore';
import { ChatStore } from './ChatStore';
import { OrderStore } from './OrderStore';

export const Store = types
  .model('Store', {
    preLoginStore: MaybeEmptyModel(PreLoginStore),
    accountStore: MaybeEmptyModel(AccountStore),
    registerStore: MaybeEmptyModel(RegisterStore),
    warehouseStore: MaybeEmptyModel(WarehouseStore),
    orderStore: MaybeEmptyModel(OrderStore),
    chatStore: MaybeEmptyModel(ChatStore),
  })
  .views((self) => ({
    hasBeenBuilt() {
      return (
        tryResolve(self, '/preLoginStore') &&
        tryResolve(self, '/accountStore') &&
        tryResolve(self, '/registerStore') &&
        tryResolve(self, '/chatStore') &&
        tryResolve(self, '/orderStore') &&
        tryResolve(self, '/warehouseStore')
      );
    },
  }))
  .actions((self) => ({
    clear() {
      const snap = castToSnapshot(Store.create());
      applySnapshot(self, snap);
    },
  }));
